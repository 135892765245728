import {
  CheckIcon,
  PencilIcon,
  TicketIcon,
  UserCircleIcon,
  UserIcon,
  BackwardIcon,
} from "@heroicons/react/24/outline"
import { CameraIcon } from "@heroicons/react/24/outline"
import axios from "axios"
import React from "react"
import { useContext } from "react"
import { useState } from "react"
import { useEffect } from "react"
import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import ListImages from "../assets"
import { GeneralContext } from "../context/context"
import Provinsi from "./provinsi.json"
import PaidTicket from "../components/paidTicket"
import UnpaidTicket from "../components/unpaidTicket"
import { GHButton } from "../components/GHSystem"
import {
  ReverseStatusPembelian,
  ReverseTipePembelian,
  StatusPembelian,
  TipePembelian,
} from "../constants/enums"
import { format } from "date-fns/esm"
import { id as idID } from "date-fns/locale"
import {
  axiosWithInterceptor,
  catchAxiosError,
  formatRupiah,
  getAxiosConfig,
} from "../utils"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"
import { useStoreActions, useStoreState } from "easy-peasy"
import { Image, PasswordCheck, SmsEdit } from "iconsax-react"
import PhotoGalleryThumbnail from "../components/PhotoGalleryThumbnail"
import { debounce } from "lodash"

const listForm = [
  {
    id: "nik",
    label: "No KTP",
  },
  {
    id: "fullname",
    label: "Nama Lengkap",
  },
  {
    id: "phone_number",
    label: "Nomor Telepon",
  },
]

function Profile() {
  const [profileData, setProfileData] = useState({})
  const [avatar, setAvatar] = useState("")
  const [imageUrl, setImageUrl] = useState("")
  const [currentTab, setCurrentTab] = useState("profile")
  const [tabPembelian, setTabPembelian] = useState("ticket")
  const [filter, setFilter] = useState("all")
  const [paid, setPaid] = useState(true)
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const token = localStorage.getItem("token") || ""
  const [currentProvinceId, setCurrentProvinceId] = useState(11)
  const [cityList, setCityList] = useState([])
  const [ticketsData, setTicketsData] = useState([])
  const { section, subsection } = useParams()
  const updateUserProfile = useStoreActions(
    (actions) => actions.updateUserProfile
  )

  useEffect(() => {
    const getCity = async () => {
      await axios
        .get(
          `https://www.emsifa.com/api-wilayah-indonesia/api/regencies/${currentProvinceId}.json`
        )
        .then((response) => {
          setCityList(response.data)
        })
      // .catch((error) => console.log(error))
    }
    getCity()
  }, [currentProvinceId])
  useEffect(() => {
    const getData = async () => {
      const token = localStorage.getItem("token")
      const customAxios = axiosWithInterceptor()
      await customAxios
        .get(`${process.env.REACT_APP_BASE_URL}/apps/user-profile`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(async ({ data }) => {
          if (data.user) {
            setProfileData(data.user)
            let prov = Provinsi.find(
              (x) => x.name === data.user.profile.province
            )
            setCurrentProvinceId(prov?.id ?? 0)
            const id = data.user.profile.user_id
            const ticketData = await axios.get(
              `${process.env.REACT_APP_BASE_URL}/apps/payment/${id}/user`
            )
            setTicketsData(ticketData.data.payment)
          }
        })
        .catch((error) => {
          navigate("/")
        })
    }

    getData()
  }, [])
  const logoutHandler = (e) => {
    localStorage.clear()
    navigate("/")
    window.location.reload()
  }
  const profileHandler = (e) => {
    setProfileData((prevState) => {
      return {
        ...prevState,
        profile: {
          ...prevState.profile,
          [e.target.id]: e.target.value,
        },
      }
    })
  }
  const provinceHandler = (e) => {
    const filteredValue = Provinsi.filter((x) => x.name === e.target.value)
    setProfileData((prevState) => {
      return {
        ...prevState,
        profile: {
          ...prevState.profile,
          province: filteredValue[0].name,
        },
      }
    })
    setCurrentProvinceId(filteredValue[0].id)
  }
  const updateProfile = async () => {
    const { profile } = profileData
    if (!avatar) {
      await axios({
        method: "put",
        url: `${process.env.REACT_APP_BASE_URL}/apps/edit-profile`,
        data: {
          nik: profile.nik,
          fullname: profile.fullname,
          phone_number: profile.phone_number,
          birthdate: profile.birthdate,
          province: profile.province,
          city: profile.city,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          toast("Profile Updated")
          if (response.data) {
            updateUserProfile(response.data.profile)
          }
        })
        .catch((error) => toast(error.message))
    } else {
      const formData = new FormData()
      formData.append("file_type", "img")
      formData.append("nik", profile.nik)
      formData.append("fullname", profile.fullname)
      formData.append("phone_number", profile.phone_number)
      formData.append("birthdate", profile.birthdate)
      formData.append("province", profile.province)
      formData.append("city", profile.city)
      formData.append("avatar", avatar)

      await axios({
        method: "put",
        url: `${process.env.REACT_APP_BASE_URL}/apps/edit-profile`,
        data: formData,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => toast("Profile Updated"))
        .catch((error) => toast(error.message))
    }
  }

  useEffect(() => {
    if (section) {
      setCurrentTab(`${section}`)
      if (subsection) {
        setTabPembelian(`${subsection}`)
      }
    }
  }, [section, subsection])

  return (
    <Layout>
      <>
        <Helmet>
          <title>{`Edit Profile | Gue Hadir`}</title>
          <meta name="description" content={`Halaman Edit Profile`} />
        </Helmet>
        {profileData ? (
          <div className="flex items-center bg-semiblack text-white justify-center">
            <div className="flex container flex-col p-5 md:p-12 space-y-5 lg:space-y-0 lg:flex-row lg:space-x-12 ">
              <div>
                {/* Box 1  */}
                <div className="p-4 rounded-md flex flex-col space-y-5 shadow-event-card">
                  {/* Card  */}
                  <div
                    className={`flex items-center space-x-5 p-5 rounded-md ${
                      profileData?.profile?.badge?.name === "bronze"
                        ? "bg-[#C3792F]"
                        : profileData?.profie?.badge?.name === "silver"
                        ? "bg-[#B6B6B6]"
                        : "bg-[#BD8B3A]"
                    }`}
                  >
                    <div>
                      {profileData.profile?.avatar ? (
                        <img
                          className="rounded-full h-[4rem] w-[4rem]"
                          src={profileData?.profile?.avatar}
                          alt=""
                        />
                      ) : (
                        <div className="h-[4rem] w-[4rem] p-2 bg-background flex items-center justify-center rounded-full">
                          <UserIcon />
                        </div>
                      )}
                    </div>
                    <div>
                      <div className="font-semibold text-[1.25rem]">
                        {profileData?.username}
                      </div>
                      <div>{profileData?.profile?.badge?.name}</div>
                      <div>
                        Poinmu :{" "}
                        <span className="font-semibold">
                          {profileData?.balance}
                        </span>
                      </div>
                    </div>
                  </div>
                  {/* Email  */}
                  <div className="flex flex-col space-y-3">
                    <div className="flex items-center space-x-3">
                      <div className="h-[35px] w-[4px] bg-primary rounded-md"></div>
                      <div className="font-semibold text-[1.15rem]">Email</div>
                      {profileData?.email_verification && (
                        <div className="flex items-center space-x-2">
                          <div>Verified</div>
                          <div className="p-2 rounded-full bg-primary">
                            <CheckIcon className="text-semiblack h-[1rem] w-auto" />
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="my-3 w-full h-[1px] bg-[#303030]"></div>
                    <div>
                      <div className="flex items-center justify-between">
                        <div className="font-semibold text-[1.15rem]">
                          {profileData?.email}
                        </div>
                        <PencilIcon className="h-[1.25rem] w-auto text-primary" />
                      </div>
                      <div className="mt-2 text-secondary">
                        Informasi penting terkait akunmu akan dikirim ke email
                        ini
                      </div>
                    </div>
                  </div>
                </div>
                {/* Box 2  */}
                <div className="bg-semiblack mt-5 shadow-event-card">
                  <div
                    onClick={() => setCurrentTab("profile")}
                    className={`${
                      currentTab === "profile" ? "text-primary" : ""
                    } p-3 flex items-center space-x-3 cursor-pointer`}
                  >
                    <UserCircleIcon className="h-[2rem] w-auto" />
                    <div>Edit Profile</div>
                  </div>
                  <div className="h-[1px] w-full bg-[#303030]"></div>
                  <div
                    onClick={() => setCurrentTab("payment")}
                    className={`${
                      currentTab === "payment" ? "text-primary" : ""
                    } p-3 flex items-center space-x-3 cursor-pointer`}
                  >
                    <TicketIcon className="h-[2rem] w-auto" />
                    <div>Pesananmu</div>
                  </div>
                  <div className="h-[1px] w-full bg-[#303030]"></div>
                  {/* ganti email */}
                  <div
                    onClick={() => setCurrentTab("gallery-photo")}
                    className={`${
                      currentTab === "gallery-photo" ? "text-primary" : ""
                    } p-3 flex items-center space-x-3 cursor-pointer`}
                  >
                    <Image
                      className={`border-[2px] rounded-[2px] ${
                        currentTab === "gallery-photo" ? "border-primary" : ""
                      }`}
                    />
                    <div>Gallery Photo</div>
                  </div>
                  {/* end ganti email */}
                  <div className="h-[1px] w-full bg-[#303030]"></div>
                  {/* ganti email */}
                  <div
                    onClick={() => setCurrentTab("change-email")}
                    className={`${
                      currentTab === "change-email" ? "text-primary" : ""
                    } p-3 flex items-center space-x-3 cursor-pointer`}
                  >
                    <SmsEdit className="h-[2rem] w-auto" />
                    <div>Ganti Email</div>
                  </div>
                  {/* end ganti email */}
                  <div className="h-[1px] w-full bg-[#303030]"></div>
                  {/* ganti password */}
                  <div
                    onClick={() => setCurrentTab("change-password")}
                    className={`${
                      currentTab === "change-password" ? "text-primary" : ""
                    } p-3 flex items-center space-x-3 cursor-pointer`}
                  >
                    <PasswordCheck className="h-[2rem] w-auto" />
                    <div>Ganti Kata Sandi</div>
                  </div>
                  {/* end ganti password */}
                  <div className="h-[1px] w-full bg-[#303030]"></div>
                  <div
                    onClick={() => logoutHandler()}
                    className={`p-3 flex items-center space-x-3 cursor-pointer`}
                  >
                    <img
                      className="h-[2rem] w-auto"
                      src={ListImages.Signout}
                      alt=""
                    />
                    <div>Logout</div>
                  </div>
                </div>
              </div>

              {/* Current Tab  */}
              {currentTab === "profile" && (
                <div className="flex flex-col w-full">
                  <div className="text-[1.25rem] font-semibold">
                    Detail Akun
                  </div>
                  <div className="flex items-center space-x-3 mt-5">
                    <div>
                      {avatar === "" ? (
                        <div className="relative cursor-pointer h-[5rem] w-[5rem] p-2 bg-background rounded-full flex items-center justify-center overflow-hidden">
                          <UserIcon className="w-auto h-[3rem]" />
                          <label
                            htmlFor="file"
                            className="absolute left-0 top-0 right-0 bottom-0 flex items-center justify-center"
                            style={{ background: "rgba(0,0,0,0.3)" }}
                          >
                            <CameraIcon className="h-[2rem] w-auto" />
                            <input
                              onChange={(e) => {
                                setAvatar(e.target.files[0])
                                setImageUrl(
                                  URL.createObjectURL(e.target.files[0])
                                )
                              }}
                              id="file"
                              className="w-0"
                              type="file"
                            />
                          </label>
                        </div>
                      ) : (
                        <div className="relative cursor-pointer h-[5rem] w-[5rem] rounded-full overflow-hidden">
                          <img className="" src={imageUrl} alt="" />
                          <label
                            htmlFor="file"
                            className="absolute left-0 top-0 right-0 bottom-0 flex items-center justify-center"
                            style={{ background: "rgba(0,0,0,0.3)" }}
                          >
                            <CameraIcon className="h-[2rem] w-auto" />
                            <input
                              onChange={(e) => {
                                setAvatar(e.target.files[0])
                                setImageUrl(
                                  URL.createObjectURL(e.target.files[0])
                                )
                              }}
                              id="file"
                              className="w-0"
                              type="file"
                            />
                          </label>
                        </div>
                      )}
                    </div>
                    <div>
                      <div>Avatar</div>
                      <div>Gunakan gambar persegi beresolusi maksimal 1MB</div>
                    </div>
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-5 mt-12 w-full">
                    {listForm.map((x) => {
                      return (
                        <div key={x.id} className="flex flex-col space-y-3">
                          <label>{x.label}</label>
                          <input
                            onChange={(e) => profileHandler(e)}
                            id={x.id}
                            className="bg-background p-3 rounded-md w-full outline-none"
                            value={profileData?.profile?.[x.id]}
                          />
                        </div>
                      )
                    })}
                    <div className="flex flex-col space-y-3">
                      <label>Tanggal Lahir</label>
                      <input
                        onChange={(e) => profileHandler(e)}
                        id="birthdate"
                        type="date"
                        className="bg-background p-3 rounded-md w-full outline-none"
                        value={profileData?.profile?.birthdate}
                      />
                    </div>
                    <div className="flex flex-col space-y-3">
                      <label>Provinsi</label>
                      <select
                        value={profileData?.profile?.province}
                        onChange={(e) => provinceHandler(e)}
                        id="province"
                        className="bg-background p-3 rounded-md w-full outline-none"
                      >
                        {Provinsi.map((x) => {
                          return (
                            <option key={x.name} value={x.name}>
                              {x.name}
                            </option>
                          )
                        })}
                      </select>
                    </div>
                    <div className="flex flex-col space-y-3">
                      <label>Kota Domisili</label>
                      <select
                        onChange={(e) => profileHandler(e)}
                        id="city"
                        className="bg-background p-3 rounded-md w-full outline-none"
                        value={profileData?.profile?.city}
                      >
                        {cityList?.map((x) => {
                          return (
                            <option key={x.name} value={x.name} id={x.id}>
                              {x.name}
                            </option>
                          )
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="flex justify-end mt-5">
                    <button
                      onClick={() => updateProfile()}
                      className="bg-primary p-2 rounded-md text-black font-semibold px-4"
                    >
                      {loading ? "Merubah.." : "Simpan Perubahan"}
                    </button>
                  </div>
                </div>
              )}

              {currentTab === "payment" && (
                <div className="flex flex-col w-full gap-4">
                  <div className="flex">
                    <div
                      onClick={() => {
                        setTabPembelian("ticket")
                        setFilter("all")
                      }}
                      className={`${
                        tabPembelian === "ticket"
                          ? "border-primary text-primary"
                          : "border-b-primary-disabled"
                      } flex-1 text-center border-b-[1px] cursor-pointer`}
                    >
                      <p className="p-4 text-sm font-semibold">Your Ticket</p>
                    </div>
                    <div
                      onClick={() => {
                        setTabPembelian("photo")
                        setFilter("all")
                      }}
                      className={`${
                        tabPembelian === "photo"
                          ? "border-primary text-primary"
                          : "border-b-primary-disabled"
                      } flex-1 text-center border-b-[1px] cursor-pointer`}
                    >
                      <p className="p-4 text-sm font-semibold">Your Photo</p>
                    </div>
                  </div>
                  <div className="flex gap-3 overflow-x-auto">
                    <FilterItem
                      isActive={filter === "all"}
                      onClick={() => setFilter("all")}
                    >
                      All
                    </FilterItem>
                    <FilterItem
                      isActive={filter === "paid"}
                      onClick={() => setFilter("paid")}
                    >
                      Selesai
                    </FilterItem>
                    <FilterItem
                      isActive={filter === "unpaid"}
                      onClick={() => setFilter("unpaid")}
                    >
                      Belum Bayar
                    </FilterItem>
                    <FilterItem
                      isActive={filter === "expired"}
                      onClick={() => setFilter("expired")}
                    >
                      Expired
                    </FilterItem>
                    <FilterItem
                      isActive={filter === "cancelled"}
                      onClick={() => setFilter("cancelled")}
                    >
                      Dibatalkan
                    </FilterItem>
                  </div>
                  <div className="flex flex-col gap-3 min-h-full">
                    {ticketsData.length ? (
                      ticketsData
                        .filter((item) => {
                          return (
                            item.type === TipePembelian[tabPembelian] &&
                            (item.status === StatusPembelian[filter] ||
                              filter === "all")
                          )
                        })
                        .map((payment) => <PaymentCard payment={payment} />)
                    ) : (
                      <div className="w-full h-full flex justify-center items-center flex-1">
                        <p className="text-base text-t-primary-disabled font-semibold">
                          Belum ada transaksi
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              )}
              {currentTab === "gallery-photo" && <GalleryPhoto />}
              {currentTab === "change-password" && <ChangePassword />}
              {currentTab === "change-email" && <ChangeEmail />}
              {/* TODO: change-email */}
            </div>
          </div>
        ) : null}
        {/* <div className="flex lg:hidden min-h-[70vh] bg-gh-black-100 h-full text-white flex-col items-center px-4 py-6">
          <div className="container flex-col flex gap-3 h-full">
            {section === "edit-profile" ? (
              <>
                <div className="flex gap-3 items-center">
                  <p>Home</p>
                  <p>&gt;</p>
                  <p className="text-primary">Edit Profile</p>
                </div>
                <div className="flex flex-col w-full">
                  <div className="text-[1.25rem] font-semibold">
                    Detail Akun
                  </div>
                  <div className="flex items-center space-x-3 mt-5">
                    <div>
                      {avatar === "" ? (
                        <div className="relative cursor-pointer h-[5rem] w-[5rem] p-2 bg-background rounded-full flex items-center justify-center overflow-hidden">
                          <UserIcon className="w-auto h-[3rem]" />
                          <label
                            htmlFor="file"
                            className="absolute left-0 top-0 right-0 bottom-0 flex items-center justify-center"
                            style={{ background: "rgba(0,0,0,0.3)" }}
                          >
                            <CameraIcon className="h-[2rem] w-auto" />
                            <input
                              onChange={(e) => {
                                setAvatar(e.target.files[0])
                                setImageUrl(
                                  URL.createObjectURL(e.target.files[0])
                                )
                              }}
                              id="file"
                              className="w-0"
                              type="file"
                            />
                          </label>
                        </div>
                      ) : (
                        <div className="relative cursor-pointer h-[5rem] w-[5rem] rounded-full overflow-hidden">
                          <img className="" src={imageUrl} alt="" />
                          <label
                            htmlFor="file"
                            className="absolute left-0 top-0 right-0 bottom-0 flex items-center justify-center"
                            style={{ background: "rgba(0,0,0,0.3)" }}
                          >
                            <CameraIcon className="h-[2rem] w-auto" />
                            <input
                              onChange={(e) => {
                                setAvatar(e.target.files[0])
                                setImageUrl(
                                  URL.createObjectURL(e.target.files[0])
                                )
                              }}
                              id="file"
                              className="w-0"
                              type="file"
                            />
                          </label>
                        </div>
                      )}
                    </div>
                    <div>
                      <div>Avatar</div>
                      <div>Gunakan gambar persegi beresolusi maksimal 1MB</div>
                    </div>
                  </div>
                  <div className="flex flex-col items-stretch">
                    <div className="flex justify-between border-b-[1px] pb-3 border-[#303030]">
                      <div className="flex items-center space-x-3">
                        <div className="h-[35px] w-[4px] bg-primary rounded-md"></div>
                        <div className="font-semibold text-[1.15rem]">
                          Email
                        </div>
                      </div>
                      <div className="flex items-center space-x-2">
                        <div className="text-primary">Verified</div>
                        <div className="p-2 rounded-full bg-primary">
                          <CheckIcon className="text-semiblack h-2 w-auto" />
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="flex items-center justify-between">
                        <div className="font-semibold text-[1.15rem]">
                          {profileData?.email}
                        </div>
                      </div>
                      <div className="mt-2 text-secondary">
                        Informasi penting terkait akunmu akan dikirim ke email
                        ini
                      </div>
                    </div>
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-5 mt-12 w-full">
                    {listForm.map((x) => {
                      return (
                        <div key={x.id} className="flex flex-col space-y-3">
                          <label>{x.label}</label>
                          <input
                            onChange={(e) => profileHandler(e)}
                            id={x.id}
                            className="bg-background p-3 rounded-md w-full outline-none"
                            value={profileData?.profile?.[x.id]}
                          />
                        </div>
                      )
                    })}
                    <div className="flex flex-col space-y-3">
                      <label>Tanggal Lahir</label>
                      <input
                        onChange={(e) => profileHandler(e)}
                        id="birthdate"
                        type="date"
                        className="bg-background p-3 rounded-md w-full outline-none"
                        value={profileData?.profile?.birthdate}
                      />
                    </div>
                    <div className="flex flex-col space-y-3">
                      <label>Provinsi</label>
                      <select
                        value={profileData?.profile?.province}
                        onChange={(e) => provinceHandler(e)}
                        id="province"
                        className="bg-background p-3 rounded-md w-full outline-none"
                      >
                        {Provinsi.map((x) => {
                          return (
                            <option key={x.name} value={x.name}>
                              {x.name}
                            </option>
                          )
                        })}
                      </select>
                    </div>
                    <div className="flex flex-col space-y-3">
                      <label>Kota Domisili</label>
                      <select
                        onChange={(e) => profileHandler(e)}
                        id="city"
                        className="bg-background p-3 rounded-md w-full outline-none"
                        value={profileData?.profile?.city}
                      >
                        {cityList?.map((x) => {
                          return (
                            <option key={x.name} value={x.name} id={x.id}>
                              {x.name}
                            </option>
                          )
                        })}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="fixed bottom-0 left-0 bg-gh-black-100 w-screen flex px-5 py-4 lg:hidden">
                  <GHButton onClick={() => updateProfile()} className="flex-1">
                    {loading ? "Merubah..." : "Simpan Perubahan"}
                  </GHButton>
                </div>
              </>
            ) : null}
            {section === "pembelian" ? (
              <>
                <div className="flex gap-3 items-center">
                  <p>Home</p>
                  <p>&gt;</p>
                  <p className="text-primary">Pembelian</p>
                </div>
                <div className="flex flex-col w-full gap-4 h-full">
                  <div className="flex">
                    <div
                      onClick={() => {
                        setTabPembelian("ticket")
                        setFilter("all")
                      }}
                      className={`${
                        tabPembelian === "ticket"
                          ? "border-primary text-primary"
                          : "border-b-primary-disabled"
                      } flex-1 text-center border-b-[1px] cursor-pointer`}
                    >
                      <p className="p-4 text-sm font-semibold">Your Ticket</p>
                    </div>
                    <div
                      onClick={() => {
                        setTabPembelian("photo")
                        setFilter("all")
                      }}
                      className={`${
                        tabPembelian === "photo"
                          ? "border-primary text-primary"
                          : "border-b-primary-disabled"
                      } flex-1 text-center border-b-[1px] cursor-pointer`}
                    >
                      <p className="p-4 text-sm font-semibold">Your Photo</p>
                    </div>
                  </div>
                  <div className="flex gap-3 overflow-x-auto">
                    <FilterItem
                      isActive={filter === "all"}
                      onClick={() => setFilter("all")}
                    >
                      All
                    </FilterItem>
                    <FilterItem
                      isActive={filter === "paid"}
                      onClick={() => setFilter("paid")}
                    >
                      Selesai
                    </FilterItem>
                    <FilterItem
                      isActive={filter === "unpaid"}
                      onClick={() => setFilter("unpaid")}
                    >
                      Belum Bayar
                    </FilterItem>
                    <FilterItem
                      isActive={filter === "expired"}
                      onClick={() => setFilter("expired")}
                    >
                      Expired
                    </FilterItem>
                    <FilterItem
                      isActive={filter === "cancelled"}
                      onClick={() => setFilter("cancelled")}
                    >
                      Dibatalkan
                    </FilterItem>
                  </div>
                  <div className="flex flex-col gap-3 min-h-full">
                    {ticketsData.length ? (
                      ticketsData
                        .filter((item) => {
                          return (
                            item.type === TipePembelian[tabPembelian] &&
                            (item.status === StatusPembelian[filter] ||
                              filter === "all")
                          )
                        })
                        .map((payment) => <PaymentCard payment={payment} />)
                    ) : (
                      <div className="w-full h-full flex justify-center items-center flex-1">
                        <p className="text-base text-t-primary-disabled font-semibold">
                          Belum ada transaksi
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </>
            ) : null}
            {section === "gallery-photo" && <GalleryPhoto />}
          </div>
        </div> */}
      </>
    </Layout>
  )
}

const GalleryPhoto = () => {
  const gallery = useStoreState((state) => state.gallery)
  const scrollEnd = useStoreState((state) => state.scrollEnd)
  const selectedPhoto = useStoreState((state) => state.selectedPhoto)
  const getGallery = useStoreActions((action) => action.getGallery)
  const setSelectedPhoto = useStoreActions((action) => action.setSelectedPhoto)
  const resetSelectedPhoto = useStoreActions(
    (action) => action.resetSelectedPhoto
  )
  const downloadPhotos = useStoreActions((action) => action.downloadPhotos)
  const [selectAll, setSelectAll] = useState(false)

  useEffect(() => {
    if (scrollEnd) {
      debouncedRequest()
    }
    // eslint-disable-next-line
  }, [scrollEnd])
  const debouncedRequest = debounce(() => {
    getGallery()
  }, 500)

  useEffect(() => {
    if (selectedPhoto.length === gallery.row.length) {
      setSelectAll(true)
    } else {
      setSelectAll(false)
    }
  }, [selectedPhoto, gallery])

  return (
    <div className="w-full min-h-[70vh] flex flex-col rounded-[7px] p-4 space-y-8 shadow-event-card bg-gh-black-100 text-secondary">
      <p className="text-2xl font-semibold">Gallery Photo</p>
      <div
        className="flex flex-col justify-between
      space-y-2
      sm:flex-row sm:space-y-0
      "
      >
        <span
          className="flex cursor-pointer items-center space-x-4"
          onClick={() => {
            if (!selectAll) {
              setSelectedPhoto(gallery.row.map((item) => item.photo.id))
            } else {
              resetSelectedPhoto()
            }
          }}
        >
          <div className="min-h-5 min-w-5 bg-black rounded-sm">
            <div className="h-5 w-5">
              {selectAll && (
                <CheckIcon
                  className="h-full w-auto text-primary"
                  title="Remove from Collection"
                />
              )}
            </div>
          </div>
          <p>Pilih Semua Photo</p>
        </span>
        <button
          onClick={async () => {
            let res = await downloadPhotos().catch((err) => err)
            if (res instanceof Error) {
              toast(res.message)
            }
          }}
          className="font-semibold bg-[#1D1D1D] py-[9px] px-[30px] rounded-[5px] border-[1px] border-b-primary-disabled"
        >
          Download Photo
        </button>
      </div>
      {gallery.row.length ? (
        <div
          className="grid grid-cols-2 
        md:grid-cols-3 xl:grid-cols-4
        gap-2 lg:gap-4"
        >
          {gallery.row.map((item, index) => (
            <PhotoGalleryThumbnail key={index} photo={item.photo} />
          ))}
        </div>
      ) : (
        <div className="flex-1 w-full flex justify-center items-center">
          <p className="font-semibold text-base text-t-primary-disabled">
            Belum ada Photo
          </p>
        </div>
      )}
    </div>
  )
}

const ChangePassword = () => {
  const [email, setEmail] = useState("")
  const token = localStorage.getItem("token")

  const sendChangePassword = async () => {
    if (email) {
      await axios
        .post(`${process.env.REACT_APP_BASE_URL}/apps/forgot`, {
          email: email,
          page_url: `${window.location.origin}/reset-password`,
        })
        .then((response) => {
          toast(response.data.message)
        })
        .catch((error) => {
          toast(error.response.data.message)
        })
    } else {
      return toast.error("Masukan email terlebih dahulu")
    }
  }

  return (
    <div className="w-full h-fit py-5 flex flex-col rounded-[7px] p-4 space-y-8 shadow-event-card bg-gh-black-100 text-secondary">
      <p className="text-2xl font-semibold">Ganti Kata Sandi</p>
      <div className="flex flex-col w-full space-y-5 justify-center items-center">
        <div className="flex flex-col space-y-3">
          <label>
            Massukan email kamu sesuai dengan email akun untuk ubah kata sandi
          </label>
          <input
            type="email"
            placeholder="Your Email"
            onChange={(e) => setEmail(e.currentTarget.value)}
            className="bg-background p-3 rounded-md w-full outline-none"
          />
        </div>
        <GHButton onClick={sendChangePassword}>Lanjutkan</GHButton>
      </div>
    </div>
  )
}

const ChangeEmail = () => {
  const [email, setEmail] = useState("")
  const [newEmail, setNewEmail] = useState("")
  const [password, setPassword] = useState("")
  const token = localStorage.getItem("token")
  const [tokenValue, setTokenValue] = useState("")
  const [otp, setOtp] = useState("")
  const [isOtp, setIsOtp] = useState(false)

  const sendConfirmationAcc = async () => {
    if (email && password) {
      await axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/apps/change-email-token`,
          {
            email: email,
            password: password,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          toast(response.data.message)
          setEmail("")
          setPassword("")
          setTokenValue(response.data.data.token)
        })
        .catch((error) => {
          toast(error.response.data.message)
        })
    } else {
      return toast.error("Email dan password tidak")
    }
  }

  const sendChangeEmail = async () => {
    if (newEmail) {
      await axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/apps/change-email`,
          {
            new_email: newEmail,
            token: tokenValue,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          if (response.data.success) {
            toast(response.data.message)
            setNewEmail("")
            setTokenValue("")
            setIsOtp(true)
          } else {
            toast(response.data.message)
          }
        })
        .catch((error) => {
          toast(error.response.data.message)
        })
    } else {
      return toast.error("Email tidak boleh kosong")
    }
  }

  const sendOTP = async () => {
    if (otp) {
      await axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/apps/change-email-otp`,
          {
            otp: Number(otp),
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          if (response.data.success) {
            toast(response.data.message)
            setOtp("")
            setIsOtp(false)
          } else {
            toast(response.data.message)
          }
        })
        .catch((error) => {
          toast(error.response.data.message)
        })
    } else {
      return toast.error("Email tidak boleh kosong")
    }
  }

  return (
    <>
      {tokenValue ? (
        <div className="w-full h-fit py-5 flex flex-col rounded-[7px] p-4 space-y-8 shadow-event-card bg-gh-black-100 text-secondary">
          <p className="text-2xl font-semibold">Ganti Email</p>
          <div className="flex xl:flex-row xl:space-x-5 xl:space-y-0 lg:space-y-4 md:space-y-4 sm:space-y-4 space-y-4 lg:flex-col md:flex-col sm:flex-col flex-col w-full justify-center items-center">
            <div className="flex w-full flex-col space-y-3">
              <label>Email Baru</label>
              <input
                type="email"
                value={`${newEmail}`}
                placeholder="Email Baru Kamu"
                onChange={(e) => setNewEmail(e.currentTarget.value)}
                className="bg-background p-3 rounded-md w-full outline-none"
              />
            </div>
            <div className="flex w-full flex-col space-y-3">
              <label>Token</label>
              <input
                placeholder="Kode Token"
                value={tokenValue}
                className="bg-background p-3 rounded-md w-full outline-none"
              />
            </div>
          </div>
          <GHButton onClick={sendChangeEmail}>Submit</GHButton>
        </div>
      ) : (
        <div className="w-full h-fit py-5 flex flex-col rounded-[7px] p-4 space-y-8 shadow-event-card bg-gh-black-100 text-secondary">
          {isOtp ? (
            <>
              <p className="text-2xl font-semibold">Your OTP</p>
              <div className="flex w-full flex-col space-y-3">
                <label>Masukan kode OTP</label>
                <input
                  type="number"
                  value={`${otp}`}
                  placeholder="Kode OTP"
                  onChange={(e) => setOtp(e.currentTarget.value)}
                  className="bg-background p-3 rounded-md w-full outline-none"
                />
              </div>
              <GHButton onClick={sendOTP}>Submit</GHButton>
            </>
          ) : (
            <>
              <p className="text-2xl font-semibold">Konfirmasi Akun</p>
              <div className="flex xl:flex-row xl:space-x-5 xl:space-y-0 lg:space-y-4 md:space-y-4 sm:space-y-4 space-y-4 lg:flex-col md:flex-col sm:flex-col flex-col w-full justify-center items-center">
                <div className="flex w-full flex-col space-y-3">
                  <label>Konfirmasi</label>
                  <input
                    type="email"
                    value={`${email}`}
                    placeholder="Masukkan Email"
                    onChange={(e) => setEmail(e.currentTarget.value)}
                    className="bg-background p-3 rounded-md w-full outline-none"
                  />
                </div>
                <div className="flex w-full flex-col space-y-3">
                  <label>Password</label>
                  <input
                    type="password"
                    value={`${password}`}
                    placeholder="Massukan Password"
                    onChange={(e) => setPassword(e.currentTarget.value)}
                    className="bg-background p-3 rounded-md w-full outline-none"
                  />
                </div>
              </div>
              <GHButton onClick={sendConfirmationAcc}>Konfirmasi Akun</GHButton>
            </>
          )}
        </div>
      )}
    </>
  )
}

const FilterItem = (props) => {
  return (
    <div
      {...props}
      className={`${
        props.isActive ? "border-primary" : "border-b-primary-disabled"
      } border-2 p-2 rounded-md text-sm whitespace-nowrap cursor-pointer`}
    >
      {props.children}
    </div>
  )
}

const PaymentCard = (props) => {
  const navigate = useNavigate()
  const onDownloadTicket = async () => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/apps/e-ticket/${props.payment.id}`,
        {
          ...getAxiosConfig(true),
          responseType: "blob",
        }
      )
      .then((response) => {
        const suggestedFileName = response.headers["x-suggested-filename"]
        // create file link in browser's memory
        const blobURL = window.URL.createObjectURL(response.data)
        const tempLink = document.createElement("a")
        tempLink.style.display = "none"
        tempLink.href = blobURL
        tempLink.setAttribute("download", suggestedFileName)
        // Safari thinks _blank anchor are pop ups. We only want to set _blank
        // target if the browser does not support the HTML5 download attribute.
        // This allows you to download files in desktop safari if pop up blocking
        // is enabled.
        if (typeof tempLink.download === "undefined") {
          tempLink.setAttribute("target", "_blank")
        }
        document.body.appendChild(tempLink)
        tempLink.dispatchEvent(
          new MouseEvent("click", {
            bubbles: true,
            cancelable: true,
            view: window,
          })
        )
        document.body.removeChild(tempLink)
        setTimeout(() => {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(blobURL)
        }, 100)
      })
      .catch(catchAxiosError)
  }
  const getStatusColor = (status) => {
    switch (status) {
      case StatusPembelian.paid:
        return "text-[#10B981] bg-[#0C4331]"
      case StatusPembelian.unpaid:
        return "text-[#FACC15] bg-[#86742B]"
      case StatusPembelian.expired:
        return "text-[#EC4B62] bg-[#5F1C26]"
      default:
        return "text-[#FACC15] bg-[#86742B]"
    }
  }

  return props.payment.type === 3 ? (
    <div
      {...props}
      className="flex flex-col bg-background rounded-lg p-5 gap-3"
    >
      <div className="flex justify-between items-center border-b-[1px] pb-3 border-b-primary-disabled">
        <p>{ReverseTipePembelian[String(props.payment.type)]}</p>
        <p className={`${getStatusColor(props.payment.status)} p-1 rounded-md`}>
          {ReverseStatusPembelian[String(props.payment.status)]}
        </p>
      </div>
      <div className="flex flex-col gap-3 pb-3 border-b-[4px] border-b-primary-disabled border-dashed">
        <div className="flex gap-3 text-xs text-secondary-disabled">
          <span className="flex items-center space-x-2">
            <Image className="h-4 w-4 border-[1px] border-secondary-disabled rounded-[2px]" />
            <p>{`${props.payment.payment_details.length} Photos`}</p>
          </span>
        </div>
      </div>
      {/* photos thumbnail */}
      <div className="flex flex-wrap overflow-hidden w-full max-h-16 space-x-2">
        {props.payment.payment_details.map((detail, index) => {
          return (
            <img
              key={index}
              className="h-16 w-16 min-w-[64px] min-h-[64px] object-cover rounded-[4px]"
              src={detail?.photo?.url_thumbnail}
              alt={detail?.photo?.name}
            />
          )
        })}
      </div>
      {/* end photos thumbnail */}
      {props.payment.status === StatusPembelian.unpaid ? (
        <div className="flex">
          <p>
            Please complete this payment before{" "}
            <span className="text-primary font-semibold">
              {format(
                new Date(props.payment.expired_time),
                "EEEE, dd-MM-yyyy, HH:mm",
                { locale: idID }
              )}
            </span>
          </p>
        </div>
      ) : null}
      <div className="flex justify-between items-center text-lg font-semibold">
        <p>{formatRupiah(props.payment.amount)}</p>
        {props.payment.status === StatusPembelian.paid ? (
          <GHButton
            onClick={() => {
              // perlu di fix
              navigate("/profile/gallery-photo")
            }}
          >
            Lihat Gallery Photo
          </GHButton>
        ) : props.payment.status === StatusPembelian.unpaid ? (
          <GHButton onClick={() => navigate(`/payment/${props.payment.id}`)}>
            Bayar Tagihan
          </GHButton>
        ) : null}
      </div>
    </div>
  ) : (
    <div
      {...props}
      className="flex flex-col bg-background rounded-lg p-5 gap-3"
    >
      <div className="flex justify-between items-center border-b-[1px] pb-3 border-b-primary-disabled">
        <p>{ReverseTipePembelian[String(props.payment.type)]}</p>
        <p className={`${getStatusColor(props.payment.status)} p-1 rounded-md`}>
          {ReverseStatusPembelian[String(props.payment.status)]}
        </p>
      </div>
      <div className="flex flex-col gap-3 pb-3 border-b-[4px] border-b-primary-disabled border-dashed">
        <p className="line-clamp-2 font-semibold">
          {props.payment.event.title}
        </p>
        <div className="flex gap-3 text-xs">
          <p>
            {format(new Date(props.payment.event.start_date), "dd MMMM yyyy", {
              locale: idID,
            })}
          </p>
          <span className="text-[5px] my-auto">{`  \u2B24 `}</span>
          <p>{`${props.payment.event.start_time.substring(0, 5)} WIB`}</p>
          <p>|</p>
          <p>{`${props.payment.bookings.length} Tiket`}</p>
        </div>
      </div>
      {props.payment.status === StatusPembelian.unpaid ? (
        <div className="flex">
          <p>
            Please complete this payment before{" "}
            <span className="text-primary font-semibold">
              {format(
                new Date(props.payment.expired_time),
                "EEEE, dd-MM-yyyy, HH:mm",
                { locale: idID }
              )}
            </span>
          </p>
        </div>
      ) : null}
      <div className="flex justify-between items-center text-lg font-semibold">
        <p>{formatRupiah(props.payment.amount)}</p>
        {props.payment.status === StatusPembelian.paid ? (
          <GHButton onClick={onDownloadTicket}>Download E-Ticket</GHButton>
        ) : props.payment.status === StatusPembelian.unpaid ? (
          <GHButton onClick={() => navigate(`/payment/${props.payment.id}`)}>
            Bayar Tagihan
          </GHButton>
        ) : null}
      </div>
    </div>
  )
}

export default Profile
